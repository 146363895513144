import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { VisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import React, { FC } from "react";
import Customer from "../../../../../../model/customer/Customer";
import { Text } from "@mittwald/flow-components/dist/components/Text";

interface Props {
  visibilityController?: VisibilityController;
  center?: boolean;
  customer: Customer;
}

export const AdditionalExtensionsInProjectInfo: FC<Props> = (props) => {
  const { center, customer } = props;

  const hasAccess = customer.checkMyRoleIsIn("owner", "member");

  if (!hasAccess) {
    return null;
  }

  return (
    <TranslationProvider name="additionalExtensionsInProject" type="component">
      <Text
        _alignSelf="flex-start"
        center={center}
        i18n={{
          id: "text",
        }}
      />
    </TranslationProvider>
  );
};

export default AdditionalExtensionsInProjectInfo;

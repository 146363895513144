import { mittwaldApiRequestMocking } from "../../api/Mittwald.mocks";
import {
  demoCustomerInvite,
  demoCustomerMembership,
  demoCustomer,
  demoInvoice,
  demoInvoiceSettings,
} from "./staticDemoData";

export default (): void => {
  mittwaldApiRequestMocking.mockCustomerUpdateCustomerMembership(
    { path: { customerMembershipId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockCustomerDeleteCustomerMembership(
    { path: { customerMembershipId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockCustomerGetCustomerInvite(
    { path: { customerInviteId: "*" } },
    { status: 200, content: demoCustomerInvite },
  );

  mittwaldApiRequestMocking.mockCustomerGetCustomerMembership(
    { path: { customerMembershipId: "*" } },
    { status: 200, content: demoCustomerMembership },
  );

  mittwaldApiRequestMocking.mockCustomerAcceptCustomerInvite(
    { path: { customerInviteId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockCustomerDeclineCustomerInvite(
    { path: { customerInviteId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockCustomerDeleteCustomerInvite(
    { path: { customerInviteId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockCustomerListInvitesForCustomer(
    { path: { customerId: "*" } },
    { status: 200, content: [] },
  );

  mittwaldApiRequestMocking.mockCustomerListCustomerInvites(
    {},
    { status: 200, content: [] },
  );

  mittwaldApiRequestMocking.mockCustomerListMembershipsForCustomer(
    { path: { customerId: "*" } },
    { status: 200, content: [demoCustomerMembership] },
  );

  mittwaldApiRequestMocking.mockCustomerListCustomerMemberships(
    {},
    { status: 200, content: [demoCustomerMembership] },
  );
  mittwaldApiRequestMocking.mockOrderListCustomerOrders(
    { path: { customerId: "*" } },
    { status: 200, content: [] },
  );

  mittwaldApiRequestMocking.mockInvoiceListCustomerInvoices(
    { path: { customerId: "*" } },
    { status: 200, content: [demoInvoice] },
  );

  mittwaldApiRequestMocking.mockInvoiceDetail(
    { path: { invoiceId: "*" } },
    { status: 200, content: demoInvoice },
  );

  mittwaldApiRequestMocking.mockInvoiceGetDetailOfInvoiceSettings(
    { path: { customerId: "*" } },
    { status: 200, content: demoInvoiceSettings },
  );

  mittwaldApiRequestMocking.mockInvoiceUpdateInvoiceSettings(
    { path: { customerId: "*" } },
    { status: 200 },
  );
  mittwaldApiRequestMocking.mockCustomerRemoveAvatar(
    { path: { customerId: "*" } },
    { status: 204 },
  );

  mittwaldApiRequestMocking.mockCustomerUpdateCustomer(
    { path: { customerId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockCustomerDeleteCustomer(
    { path: { customerId: "*" } },
    { status: 200 },
  );

  mittwaldApiRequestMocking.mockCustomerListCustomers({}, () => ({
    status: 200,
    content: [demoCustomer],
  }));

  mittwaldApiRequestMocking.mockCustomerGetCustomer(
    { path: { customerId: "*" } },
    () => ({ status: 200, content: demoCustomer }),
  );

  mittwaldApiRequestMocking.mockCustomerCreateCustomer({}, () => ({
    status: 201,
    content: { customerId: demoCustomer.customerId },
  }));

  mittwaldApiRequestMocking.mockCustomerRequestAvatarUpload(
    { path: { customerId: "*" } },
    () => ({
      status: 200,
      content: { refId: "1" },
    }),
  );
};

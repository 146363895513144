import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { VisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import React, { FC } from "react";
import { AnyProject } from "../../../../../../model/project";

interface Props {
  visibilityController?: VisibilityController;
  center?: boolean;
  project: AnyProject;
}

export const AdditionalExtensionsInCustomerInfo: FC<Props> = (props) => {
  const { center, project } = props;

  const hasAccess = project.useMyMembership().data.inherited;

  if (!hasAccess) {
    return null;
  }

  return (
    <TranslationProvider name="additionalExtensionsInCustomer" type="component">
      <Text
        _alignSelf="flex-start"
        center={center}
        i18n={{
          id: "text",
        }}
      />
    </TranslationProvider>
  );
};

export default AdditionalExtensionsInCustomerInfo;

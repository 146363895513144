import { Box } from "@mittwald/flow-components/dist/components/Box";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { H2 } from "@mittwald/flow-components/dist/components/H2";
import { HorizontalRule } from "@mittwald/flow-components/dist/components/HorizontalRuler";
import { InfoBox } from "@mittwald/flow-components/dist/components/InfoBox";
import StaticInformation from "@mittwald/flow-components/dist/components/StaticInformation";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink";
import React, { FC } from "react";
import Extension from "../../../../../model/marketplace/Extension";
import ContributorDescriptionModal from "../ContributorDescriptionModal/ContributorDescriptionModal";
import DetailedDescriptionModal from "../DetailedDescriptionModal/DetailedDescriptionModal";

interface Props {
  extension: Extension;
}

const InfoBoxContent: FC<Props> = (props) => {
  const { extension } = props;

  const contributor = extension.useContributor();

  const detailedDescription = extension.data.detailedDescriptions?.de.markdown;
  const contributorDescription = contributor.data.description;

  return (
    <Box>
      <Box _alignItems="center" _flexDirection="column" _itemGap="s" _py="m">
        <H2 text={extension.data.name} />
        <Text text={extension.data.description} />
        <Box
          _flexDirection="row"
          _flexWrap
          _itemGap="m"
          _justifyContent="space-between"
        >
          {detailedDescription && (
            <TextLink
              _inline
              action={{
                fn: () =>
                  showModal(
                    "extensionDetailedDescription",
                    <DetailedDescriptionModal extension={extension} />,
                  ),
              }}
              i18n="showDetails"
            />
          )}

          {contributorDescription && (
            <TextLink
              action={{
                fn: () =>
                  showModal(
                    "contributorDescription",
                    <ContributorDescriptionModal contributor={contributor} />,
                  ),
              }}
              i18n="showContributorDetails"
            />
          )}
        </Box>
      </Box>
      <HorizontalRule _my="s" />
      <Box _itemGap="xl" _py="m">
        <StaticInformation
          label="contributor"
          text={{ text: contributor.data.name }}
        />
        <Box _alignItems="center" _flexDirection="column">
          <InfoBox.Price price={0} priceSubline="perMonth" />
          <Text _color="label" center i18n="betaPrice" small />
        </Box>
      </Box>
    </Box>
  );
};

export default InfoBoxContent;

import { Icon } from "@mittwald/flow-components/dist/components/Icon";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink";
import { iconDomain } from "@mittwald/flow-icons/dist/domain";
import { iconEmail } from "@mittwald/flow-icons/dist/email";
import React, { FC } from "react";
import Contributor from "../../../../../../model/marketplace/Contributor";

interface Props {
  contributor: Contributor;
}

const ContactSection: FC<Props> = (props) => {
  const { contributor } = props;

  return (
    <Section.Item headline="contactHeadline">
      <Text i18n="contactDescription" />

      <div style={{ display: "flex", alignItems: "center", columnGap: 8 }}>
        <Icon _color="grey-$300" icon={iconDomain} />
        <TextLink
          action={contributor.data.url}
          style={{ fontSize: "14px", fontWeight: "bold" }}
          text={contributor.data.url}
        />
      </div>

      <div style={{ display: "flex", alignItems: "center", columnGap: 8 }}>
        <Icon _color="grey-$300" icon={iconEmail} />
        <TextLink
          action={`mailto:${contributor.data.email}`}
          style={{ fontSize: "14px", fontWeight: "bold" }}
          text={contributor.data.email}
        />
      </div>
    </Section.Item>
  );
};

export default ContactSection;

import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconExtension } from "@mittwald/flow-icons/dist/extension";
import React, { FC } from "react";
import Contributor from "../../../../../model/marketplace/Contributor";
import Section from "@mittwald/flow-components/dist/components/Section";
import Text from "@mittwald/flow-components/dist/components/Text";
import ContactSection from "../../pages/ContributorDetails/components/ContactSection";

interface Props {
  contributor: Contributor;
}

export const ContributorDescriptionModal: FC<Props> = (props) => {
  const { contributor } = props;

  const visibility = useVisibilityController();

  return (
    <DefaultModal
      description={
        <Section.Layout>
          <Section.Item>
            {contributor.data.description && (
              <Text text={contributor.data.description} />
            )}
            {!contributor.data.description && (
              <Text i18n="placeholderDescription" />
            )}
          </Section.Item>
          <ContactSection contributor={contributor} />
        </Section.Layout>
      }
      headline={{ text: contributor.data.name }}
      headlineIcon={iconExtension}
      visibility={visibility}
    />
  );
};

export default ContributorDescriptionModal;

import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import invariant from "invariant";
import path from "path";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import { Cronjob } from "../cronjob/Cronjob";
import { CronjobList } from "../cronjob/CronjobList";
import { AnyDatabase, Database, DatabaseTypes } from "../database/Database";
import { MySqlDatabaseList } from "../database/MySqlDatabaseList";
import { MySqlUserList } from "../database/MySqlUserList";
import { UserInputName } from "../misc/userInput/UserInput";
import UserInputList from "../misc/userInput/UserInputList";
import {
  UserInputRecordList,
  UserInputsRecordObject,
} from "../misc/userInput/UserInputRecordList";
import { AnyProject, Project } from "../project";
import App from "./App";
import AppVersion from "./AppVersion";
import AppVersionList from "./AppVersionList";
import InstalledSystemSoftware from "./InstalledSystemSoftware";
import InstalledSystemSoftwareList from "./InstalledSystemSoftwareList";
import SystemSoftware, { SystemSoftwareNames } from "./SystemSoftware";
import SystemSoftwareVersionList from "./SystemSoftwareVersionList";

export type AppInstallationApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_App_AppInstallation;

export type AppStatus =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_App_AppInstallationStatus["state"];

export type LinkedDatabase =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_App_LinkedDatabase;

export type AppAction =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_App_Action;

export type SystemSoftwareDependency =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_App_SystemSoftwareDependency;

export type MissingDependencies =
  MittwaldApi.Paths.V2_App_Installations_AppInstallationId_Missing_Dependencies.Get.Responses.$200.Content.Application_Json;

export enum AppActions {
  start = "start",
  stop = "stop",
  restart = "restart",
}

export interface AppInstallationUpdateInput {
  userInputs?: UserInputsRecordObject;
  description?: string;
  appVersionId?: string;
  systemSoftware?: {
    [a: string]: any;
  };
  customDocumentRoot?: string;
}

export interface CopyAppInstallationInput {
  description: string;
  targetProjectId: string;
}

export interface InstallSystemSoftwareInput {
  systemSoftwareId: string;
  systemSoftwareVersionId: string;
}

export interface AppInstallationCreationInput {
  userInputs?: UserInputsRecordObject;
  description: string;
  appVersionId: string;
  projectId: string;
}

export interface ConnectDatabaseInputs {
  databaseId: string;
  databaseType?: string;
}

export interface UpdatePrimaryDatabaseInputs {
  databaseId: string;
}

export class AppInstallation {
  public readonly data: AppInstallationApiData;
  public readonly id: string;
  public readonly installedSystemSoftware: InstalledSystemSoftwareList;
  public readonly userInputs: UserInputRecordList;
  public readonly domainName: URL | undefined;
  public readonly projectId: string;
  public readonly description: string;
  public readonly isUpdating: boolean;
  public readonly isInstalling: boolean;
  public readonly customDocRootPath?: string;
  public readonly installationPath: string;

  private constructor(data: AppInstallationApiData) {
    this.data = Object.freeze(data);
    this.id = data.id;
    this.userInputs = UserInputRecordList.fromApiData(data.userInputs ?? []);
    this.domainName = this.getHost();
    invariant(data.projectId, "projectId not found");
    this.projectId = data.projectId;
    this.description = data.description;
    this.isUpdating =
      !!this.data.appVersion.current &&
      this.data.appVersion.current !== this.data.appVersion.desired;
    this.isInstalling = !this.data.appVersion.current;
    this.installedSystemSoftware = InstalledSystemSoftwareList.fromApiData(
      data.systemSoftware ?? [],
      this,
    );
    this.customDocRootPath = data.customDocumentRoot;
    this.installationPath = data.installationPath;
  }

  public static fromApiData(data: AppInstallationApiData): AppInstallation {
    return new AppInstallation(data);
  }

  public static async create(
    input: AppInstallationCreationInput,
  ): Promise<AppInstallation> {
    const { description, appVersionId, userInputs, projectId } = input;

    const response = await mittwaldApi.appRequestAppinstallation.request({
      path: { projectId },
      requestBody: {
        description,
        appVersionId,
        updatePolicy: "patchLevel",
        userInputs: UserInputRecordList.toObjectList(userInputs),
      },
    });

    assertStatus(response, 201);

    const createdAppData = await mittwaldApi.appGetAppinstallation.request({
      path: {
        appInstallationId: response.content.id,
      },
    });

    assertStatus(createdAppData, 200);

    return new AppInstallation(createdAppData.content);
  }

  public static useLoadById(id: string): AppInstallation {
    const data = mittwaldApi.appGetAppinstallation
      .getResource({ path: { appInstallationId: id } })
      .useWatchData();
    return new AppInstallation(data);
  }

  public static useLoadByPathParam(): AppInstallation {
    const { appInstallationId } = usePathParams("appInstallationId");

    return AppInstallation.useLoadById(appInstallationId);
  }

  public static useTryLoadById(id?: string): AppInstallation | undefined {
    const data = mittwaldApi.appGetAppinstallation
      .getResource(id ? { path: { appInstallationId: id } } : null)
      .useWatchData({
        optional: true,
        throwOnError: false,
      });
    return data ? new AppInstallation(data) : undefined;
  }

  public static useLinkedDatabase(
    database: Database,
  ): LinkedDatabase | undefined {
    const appInstallation = AppInstallation.useTryLoadById(
      database.linkedAppInstallationId,
    );

    return appInstallation?.data.linkedDatabases?.find(
      (d) => d.databaseId === database.id,
    );
  }

  public useApp(): App {
    return App.useLoadById(this.data.appId);
  }

  public useVersion(): AppVersion {
    const app = this.useApp();
    const versionId = this.data.appVersion.desired;
    return AppVersion.useLoadById(app, versionId);
  }

  public async update(input: AppInstallationUpdateInput): Promise<void> {
    const {
      description,
      appVersionId,
      userInputs,
      systemSoftware,
      customDocumentRoot,
    } = input;

    const userInputsList = userInputs
      ? UserInputRecordList.toObjectList(userInputs)
      : undefined;

    const response = await mittwaldApi.appPatchAppinstallation.request({
      path: {
        appInstallationId: this.id,
      },
      requestBody: {
        appVersionId,
        description,
        userInputs: userInputsList,
        systemSoftware,
        customDocumentRoot,
      },
    });

    assertStatus(response, 204);
  }

  public async updateUserInputs(
    input: AppInstallationUpdateInput,
  ): Promise<void> {
    await this.update(input);
  }

  public async updateVersion(input: AppInstallationUpdateInput): Promise<void> {
    await this.update(input);
  }

  public async installSystemSoftware(
    values: InstallSystemSoftwareInput,
  ): Promise<void> {
    await InstalledSystemSoftware.install(values, this);
  }

  private getHost(): URL | undefined {
    const hostName = this.userInputs.findByName(UserInputName.domain)?.value;

    if (hostName) {
      try {
        return new URL(hostName);
      } catch (e) {
        // fallback if user has nonsense in the userInput, should throw in prod
        return undefined;
      }
    }
  }

  public async delete(): Promise<void> {
    const response = await mittwaldApi.appUninstallAppinstallation.request({
      path: {
        appInstallationId: this.id,
      },
    });

    assertStatus(response, 204);
  }

  public async connectDatabase(values: ConnectDatabaseInputs): Promise<void> {
    const mySqlUserId =
      values.databaseType === DatabaseTypes.mySql
        ? await MySqlUserList.requestMainUserId(values.databaseId)
        : undefined;

    const response = await mittwaldApi.appLinkDatabase.request({
      path: { appInstallationId: this.id },
      requestBody: {
        databaseId: values.databaseId,
        purpose: "custom",
        databaseUserIds: mySqlUserId ? { admin: mySqlUserId } : undefined,
      },
    });

    assertStatus(response, 204);
  }

  public async updatePrimaryDatabase(
    values: ConnectDatabaseInputs,
  ): Promise<void> {
    const mySqlUserId =
      values.databaseType === DatabaseTypes.mySql
        ? await MySqlUserList.requestMainUserId(values.databaseId)
        : undefined;

    const oldDatabaseId = this.data.linkedDatabases?.find(
      (d) => d.purpose === "primary",
    )?.databaseId;

    invariant(
      oldDatabaseId,
      "No old primary database found, no update possible!",
    );

    const response = await mittwaldApi.appReplaceDatabase.request({
      path: { appInstallationId: this.id },
      requestBody: {
        oldDatabaseId: oldDatabaseId ? oldDatabaseId : "",
        newDatabaseId: values.databaseId,
        databaseUserIds: mySqlUserId ? { admin: mySqlUserId } : undefined,
      },
    });

    assertStatus(response, 204);
  }

  public async disconnectDatabase(databaseId: string): Promise<void> {
    const response = await mittwaldApi.appUnlinkDatabase.request({
      path: { appInstallationId: this.id, databaseId },
    });

    assertStatus(response, 204);
  }

  public useCronjobs(): Cronjob[] {
    return CronjobList.useLoadAllByProjectId(this.projectId).items.filter(
      (c) => c.data.appId === this.id,
    );
  }

  public useAvailableDatabases(): AnyDatabase[] {
    const mySqlDatabases = MySqlDatabaseList.useLoadAllByProjectId(
      this.projectId,
    ).useItems();

    return mySqlDatabases.filter(
      (d) => !this.data.linkedDatabases?.find((l) => l.databaseId === d.id),
    );
  }

  public useLaterVersions(): AppVersionList {
    const currentVersionId = this.useVersion().data.id;

    return this.useApp().useLaterVersions(currentVersionId);
  }

  public useUpdateAvailable(): boolean {
    return !this.useLaterVersions().isEmpty;
  }

  public useInstallableSystemSoftwares(): SystemSoftware[] {
    const systemSoftwares = SystemSoftware.useLoadAll();

    return systemSoftwares.filter(
      (s) =>
        !this.installedSystemSoftware.items.find(
          (i) => i.data.systemSoftwareId === s.id,
        ),
    );
  }

  public useUserInputsForReconfigure(): UserInputList {
    const appVersion = this.useVersion();

    return appVersion.userInputs.filter({
      lifeCycle: "reconfigure",
    });
  }

  private async executeAction(action: AppAction): Promise<void> {
    const response = await mittwaldApi.appExecuteAction.request({
      path: { appInstallationId: this.id, action },
    });

    assertStatus(response, 204);
  }

  public async start(): Promise<void> {
    await this.executeAction(AppActions.start);
  }
  public async stop(): Promise<void> {
    await this.executeAction(AppActions.stop);
  }
  public async restart(): Promise<void> {
    await this.executeAction(AppActions.restart);
  }

  public useStatus(): AppStatus {
    const data = mittwaldApi.appRetrieveStatus
      .getResource({ path: { appInstallationId: this.id } })
      .useWatchData();

    return data.state;
  }

  public async copy(values: CopyAppInstallationInput): Promise<void> {
    const response = await mittwaldApi.appRequestAppinstallationCopy.request({
      path: { appInstallationId: this.id },
      requestBody: {
        description: values.description,
        targetProjectId: values.targetProjectId,
      },
    });

    assertStatus(response, 201);
  }

  public useInstalledSystemSoftware(
    name: SystemSoftwareNames,
  ): InstalledSystemSoftware | undefined {
    return this.installedSystemSoftware.useFindByName(name);
  }

  public findPhpMinorVersion(
    php: SystemSoftware,
    phpVersions?: SystemSoftwareVersionList,
  ): string | undefined {
    const installedPhp = this.installedSystemSoftware.items.find(
      (s) => s.data.systemSoftwareId === php.id,
    );

    const phpVersion = phpVersions?.items.find(
      (v) => v.data.id === installedPhp?.data.systemSoftwareVersion.desired,
    )?.data.externalVersion;

    return phpVersion?.split(".", 2).join(".").replace("-es", "");
  }

  public useMissingDependencies(
    targetAppVersionID?: string,
  ): MissingDependencies | undefined {
    const data = mittwaldApi.appGetMissingDependenciesForAppinstallation
      .getResource(
        targetAppVersionID
          ? {
              path: { appInstallationId: this.id },
              query: { targetAppVersionID },
            }
          : null,
      )
      .useWatchData();

    return data ?? undefined;
  }

  public useInstalledPhp(): InstalledSystemSoftware | undefined {
    return this.installedSystemSoftware.useFindByName(SystemSoftwareNames.php);
  }

  public useProject(): AnyProject {
    return Project.useLoadById(this.projectId);
  }

  public useDocumentRoot(): string {
    const version = this.useVersion();
    return path.join(
      this.data.installationPath,
      this.data.customDocumentRoot ?? version.data.docRoot,
    );
  }
}

export default AppInstallation;

import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import { Database, DatabaseTypes } from "./Database";
import { MySqlUser, NewDatabaseUserInputs } from "./MySqlUser";
import { MySqlUserList } from "./MySqlUserList";

export type MySqlDatabaseApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Database_MySqlDatabase;

export interface NewMySqlDatabaseInputs {
  database: {
    description: string;
    version: string;
  };
  user: {
    password: string;
  };
  type: DatabaseTypes.mySql;
  projectId: string;
}

export class MySqlDatabase extends Database {
  public readonly data: MySqlDatabaseApiData;
  public readonly description?: string;
  public readonly type: DatabaseTypes.mySql;
  public readonly externalHostname: string;

  private constructor(data: MySqlDatabaseApiData) {
    super(data);
    this.data = Object.freeze(data);
    this.description = data.description;
    this.type = DatabaseTypes.mySql;
    this.externalHostname = data.externalHostname;
  }

  public static fromApiData(data: MySqlDatabaseApiData): MySqlDatabase {
    return new MySqlDatabase(data);
  }

  public static useLoadById(id: string): MySqlDatabase {
    const data = mittwaldApi.databaseGetMysqlDatabase
      .getResource({
        path: {
          mysqlDatabaseId: id,
        },
      })
      .useWatchData();

    return MySqlDatabase.fromApiData(data);
  }

  public static useTryLoadById(id?: string): MySqlDatabase | undefined {
    const data = mittwaldApi.databaseGetMysqlDatabase
      .getResource(
        id
          ? {
              path: {
                mysqlDatabaseId: id,
              },
            }
          : null,
      )
      .useWatchData({ optional: true });

    return data ? MySqlDatabase.fromApiData(data) : undefined;
  }

  public static useLoadByPathParam(): MySqlDatabase {
    const { databaseId } = usePathParams("databaseId");

    return MySqlDatabase.useLoadById(databaseId);
  }

  public useOptionalUserList(): MySqlUserList | undefined {
    return MySqlUserList.useTryLoadAllByDatabaseId(this.id);
  }

  public static async createNew(values: NewMySqlDatabaseInputs): Promise<void> {
    const response = await mittwaldApi.databaseCreateMysqlDatabase.request({
      requestBody: {
        database: {
          projectId: values.projectId,
          description: values.database.description,
          version: values.database.version,
        },
        user: {
          password: values.user.password,
          accessLevel: "full",
          externalAccess: false,
        },
      },
      path: {
        projectId: values.projectId,
      },
    });

    assertStatus(response, 201);
  }

  public async updateDescription(description: string): Promise<void> {
    const response =
      await mittwaldApi.databaseUpdateMysqlDatabaseDescription.request({
        path: { mysqlDatabaseId: this.id },
        requestBody: {
          description,
        },
      });

    assertStatus(response, 204);
  }

  public getMainUser(): MySqlUser | undefined {
    if (!this.data.mainUser) return undefined;
    return MySqlUser.fromApiData(this.data.mainUser);
  }

  public async delete(): Promise<void> {
    const response = await mittwaldApi.databaseDeleteMysqlDatabase.request({
      path: { mysqlDatabaseId: this.id },
    });

    assertStatus(response, 204);
  }

  public async openPhpMyAdmin(): Promise<void> {
    const mainUser = this.getMainUser();
    if (!mainUser) return;
    if (mainUser.data.status !== "ready") return;
    const phpMyAdmin = await mainUser.getPhpMyAdminUrl();

    window.open(phpMyAdmin, "_blank");
  }

  public async createUser(values: NewDatabaseUserInputs): Promise<void> {
    await MySqlUser.createNew(values, this.id);
  }

  public static mapDatabaseVersionIdToVersionString(versionId: string): string {
    switch (versionId) {
      case "91bbc8ea-9308-4b7f-88dc-1a2873d02c8c":
        return "5.6";
      case "1be01968-784d-434c-aa05-2e64a51ced28":
        return "5.7";
      case "37931c38-e05e-470b-9e22-a035ef9f383b":
        return "8.0";
      default:
        return "";
    }
  }
}
